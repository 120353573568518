import React, { FC,useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "hooks";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import BackgroundSection from "components/BackgroundSection/BackgroundSection";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 DIRECCIÓN",
    desc: "Av. del Carrilet, 237, 08907 L’Hospitalet de Llobregat, Barcelona",
  },
  {
    title: "💌 EMAIL",
    desc: "info@presenta.biz",
  },
  {
    title: "☎ LLAMANOS AL",
    desc: "+(34) 675 92 38 26",
  },
];

const contactFormFields = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const PageContact:FC<PageContactProps> = ({ className = "" }) => {

  const [messageState, setMessageState] = useState('');
  const [hiddenForm, setHiddenForm] = useState(true)
  const { name, email,phone, message, onInputChange } = useForm(contactFormFields);
  
  const handleContact = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMessageState('');
    // if (newPassword.length < 6 || confirmPassword.length < 6) {
    //   setMessageState('La contraseña debe tener al menos 6 caracteres');
    //   return;
    // }
    // if (newPassword !== confirmPassword) {
    //   setMessageState('Las contraseñas no coinciden');
    //   return;
    // }
    // try {
    //   resetPassword({ password: newPassword, token }).then((response) => {
    //     ;
    //     setMessageState(response);
    //     console.log("Responseee", response);
    //     if (response.ok) {
    //       setMessageState("Se ha realizado correctamente el cambio de constraseña");
    //       setHiddenForm(false)
    //     } else {
    //       setMessageState("Ha pasado mucho tiempo, debes solicitar un nuevo password.");
    //       setHiddenForm(false)
    //     }
    //   });


    // } catch (error) {
    //   console.log('Error al cambiar la contraseña', error);
    // }
  }
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Karaoke Room Barcelona</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6"  onSubmit={handleContact} method="post">
                <label className="block">
                  <Label>Nombre</Label>

                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    name= "name"
                    value={name}
                    onChange={onInputChange}
                  />
                </label>
                <label className="block">
                  <Label>Teléfono</Label>

                  <Input
                    type="email"
                    placeholder="0034 664 636 837"
                    className="mt-1"
                    name= "phone"
                    value={phone}
                    onChange={onInputChange}
                  />
                </label>
                <label className="block">
                  <Label>Email</Label>

                  <Input
                    type="email"
                    placeholder="tuemail@karaokeroomsbarcelona.com"
                    className="mt-1"
                    name= "email"
                    value={email}
                    onChange={onInputChange}
                  />
                  
                </label>
                <label className="block">
                  <Label>Mensaje</Label>

                  <Textarea 
                  className="mt-1" 
                  rows={6}
                  name="message"
                  value={message}
                  onChange={onInputChange}
                   />
                </label>
                <div>
                  <ButtonPrimary type="submit">Enviar consulta</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          {/* <SectionClientSay uniqueClassName="Pagecontact_" /> */}
        </div>
        {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}
      </div>
    </div>
  );
};

export default PageContact;
