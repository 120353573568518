// Desc: Hook para manejar el estado de las reservaciones
import { useDispatch, useSelector } from "react-redux";
import { roomApi } from "../api/";
import {
  addElementCarReducer,
  onAddReservationCar,
  deleteElementCarReducer,
  onLoadReservations,
  onCleanReservationCar,
} from "../store";
import { useState } from "react";

export const useReservationStore = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [reservationId, setReservationId] = useState();
  const { reservations, salas, car } = useSelector(
    (state) => state.reservations
  );



  const addElementCar = (
    roomId,
    roomName,
    srcImage,
    pricePerson,
    price,
    start,
    end,
    id,
    capacidad,
    capacidadMin,
    dateReservation
  ) => {
    console.log("date Reservation",dateReservation);
    dispatch(
      addElementCarReducer({
        roomId,
        roomName,
        srcImage,
        pricePerson,
        price,
        start,
        end,
        id,
        capacidad,
        capacidadMin,
        dateReservation,
      })
    );
  };

  //Metodo para crear una reserva
  const createReservationAdmin = async (
    price,
    start,
    end,
    idSlot,
    roomId,
    name,
    email,
    phone,
    dateReservation,
    typereservation,
    statusPayment

  ) => {
    try {

      console.log("Datos de la reserva", price,
        start,
        end,
        idSlot,
        roomId,
        name,
        email,
        phone,
        dateReservation,
        typereservation,
        statusPayment);

      // eslint-disable-next-line no-unused-vars
      const { data } = await roomApi.post("/reservations/createfromadmin",
        {
          price,
          start,
          end,
          idSlot,
          roomId,
          name,
          email,
          phone,
          dateReservation,
          typereservation,
          statusPayment
        }
      );
      getReservations({ dateReservation });
    } catch (e) {
      console.log("Error", e);
    }
  };

  //Metodo para crear una reserva
  const bookRoom = async (
    room,
    price,
    start,
    end,
    slot,
    npersons,
    user,
    date,
    typereservation
  ) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await roomApi.post("/reservations/create", {
        room,
        price,
        start,
        end,
        slot,
        npersons,
        user,
        date,
        typereservation,
      });

      //dispatch(addElementCarReducer(data.reservation));
      getReservations({ date });
    } catch (e) {
      console.log("Error", e);
    }
  };

  //Metodo para crear una reserva

  const createPayment = async (totalInvoice, gestionCost,reservations, userId, orderPayment) => {
    try {




      const { data } = await roomApi.post("/reservations/payment", {
        userId,
        reservations,
        totalInvoice,
        gestionCost,
        orderPayment
      });
      console.log("Data", data);
      //dispatch(addElementCarReducer(data.reservation));
      return data;
    } catch (e) {
      console.log("Error", e);
    }
  };
  const createSignatureForm = async (totalInvoice) => {
    try {



      const { data } = await roomApi.post("/reservations/createSignatureForm", {
        totalInvoice
      });
      console.log("Data", data);
      //dispatch(addElementCarReducer(data.reservation));
      return data;
    } catch (e) {
      console.log("Error", e);
    }
  };

  //Metodo para crear una reserva
  const closeRoom = async (
    room,
    price,
    start,
    end,
    slot,
    npersons,
    user,
    date,
    typereservation
  ) => {
    try {
      const { data } = await roomApi.post("/reservations/create", {
        room,
        price,
        start,
        end,
        slot,
        npersons,
        user,
        date,
        typereservation,
      });
      const reservationId = data.reservationSaved.id;
      // Llamamos a la función openRoom y pasamos el ID de reserva
      console.log("Se cerro la sala con este id de reserva", reservationId);
      //openRoom(reservationId);
      getReservations({ date });

      return reservationId;

      //dispatch(addElementCarReducer(data.reservation));
    } catch (e) {
      console.log("Error", e);
    }
  };

  const openRoom = async (slotid, date) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await roomApi.delete(`/reservations/${slotid}`, {
        slotid,
      });
      console.log("Se abrio la sala con este id de reserva", date);
      getReservations({date});
      //dispatch(addElementCarReducer(data.reservation));
    } catch (e) {
      console.log("Error", e);
      //console.log("Error Esto hay en el ID", reservationId);
    }
  };

  const getReservations = async ({ date }) => {
    try {
      const { data } = await roomApi.post("/reservations", { date });
      console.log("Esto hay en el store Reservation...", date)
      dispatch(onLoadReservations(data.reservations));
    } catch (e) {
      console.log("Error", e);
    }
  };

  const getReservationCountByDate = async ({ date }) => {
    try {
      const { data } = await roomApi.post("/reservations/getreservationcountbydate", { date });


      //console.log("Esto hay en el store...", data.count)
      dispatch(onLoadReservations(data.reservations));
      return data;
    } catch (e) {
      console.log("Error", e);
      return 0
    }
  };




  const setAddCar = (event) => {
    dispatch(onAddReservationCar(event));
  };
  const cleanCar = (event) => {
    dispatch(onCleanReservationCar(event));
    localStorage.removeItem("car");
  };
  const removeElementCar = (item) => {
    dispatch(deleteElementCarReducer(item));
  };


  const getReservationById = async (reservationId) => {
    //console.log("Ejecuto getDetails");
    try {
      const { data } = await roomApi.get(`/reservations/${reservationId}`);
      //console.log("Datos de la reserva:", data.reservation);
      //Checar si el valor de data reservation trae algo
      return data;
    } catch (e) {
      console.log("Error", e);
    }
  };




  return {
    //Propiedades
    reservationId,
    reservations,
    salas,
    car,

    //Metodos
    addElementCar,
    bookRoom,
    closeRoom,
    openRoom,
    createReservationAdmin,
    setAddCar,
    removeElementCar,
    getReservations,
    createPayment,
    createSignatureForm,
    getReservationById,
    cleanCar,
    getReservationCountByDate,

  };
};
