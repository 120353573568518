import "./informacionCarrito.scss";

import { FC, useEffect, useState } from "react";

import "react-datepicker/dist/react-datepicker.module.css";

import { useReservationStore } from "../../hooks";

import { useNavigate } from "react-router-dom";


export interface Car {
  id: string;
  sala_id: string;
  start: string;
  end: string;
  price: number;
  imagen: string;
  capacidadMin: string;
  capacidad: string; // Nuevo campo agregado
  ubicacion: string; // Nuevo campo agregado
}


export interface PropsCar {
  data?: Car;
}
// eslint-disable-next-line
export const InformacionCarrito: FC<PropsCar> = ({ data }) => {
  // eslint-disable-next-line
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const { car, removeElementCar } = useReservationStore();
  const [totalPrice, setTotalPrice] = useState("0.00");
  const [gestionCost, setGestionCost] = useState("0.00");
  const [deletedItemIds, setDeletedItemIds] = useState<number[]>([]);
  const navigate = useNavigate();


  //calcular total del carrito´

  const calculateTotalPrice = () => {
    let total = 0;
    car.forEach((item: any) => {
      const precio =
        typeof item.price === "string"
          ? parseFloat(item.price.replace(",", "."))
          : item.price;
      total += precio 
    });
   
    
    
    return total.toFixed(2); // Devolver el total con dos decimales
  };

  useEffect(() => {
    updateTotalPrice();
  }, [car]);

  useEffect(() => {
   
    
    
  }, [totalPrice]);

  const handleRedirectLogin = () => {
    navigate("/checkout");
  };

  const handleRemoveElementCar = (itemId: number) => {
    removeElementCar(itemId); // Eliminar la sala del carrito
    // Actualizar el estado de las salas reservadas en CalendarRoom
    setDeletedItemIds([...deletedItemIds, itemId]);
  };

  // eslint-disable-next-line
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const formatDate = (fechaOriginal: Date) => {
  // Crear un objeto Date a partir de la cadena de fecha original
      const fecha = new Date(fechaOriginal);

      // Obtener el día, el mes y el año de la fecha
      const dia = fecha.getDate();
      const mes = fecha.toLocaleString('es-ES', { month: 'long' }); // Obtener el nombre del mes en español
      const año = fecha.getFullYear();

      // Formatear la fecha en el formato deseado
      return `${dia} de ${mes} de ${año}`;
  }

  const updateTotalPrice = () => {
    let total = calculateTotalPrice();
    console.log("Recalculando", total);

    setGestionCost((parseFloat(total) * 5 / 100).toFixed(2));
    setTotalPrice(((parseFloat(total) + (parseFloat(total) * 5) / 100)).toString());
  };

  return (
    <>
      <div className="container-informacion-carrito">
        {car.map((item: any) => (
          <div>
            <div className="xl:w-[720px]  lg:w-[720px] sm:w-[600px] md:w-[700px]  bg-white m-5  relative rounded-xl items-center grid grid-cols-1 sm:grid-cols-[167px,1fr] gap-4 ">
              <div>
                <img
                  className="sm:w-[167px] sm:h-[220px] w-full h-[220px] object-cover rounded-xl "
                  src={item.srcImage}
                  alt={item.roomName}
                />
              </div>
              <div className="text-black flex flex-col justify-center m-2 w-[270px] sm:w-[285px] sm:h-[149px]">
                <span className="text-gray-800 mb-1">
                  {" "}
                  <h1 className="text-black font-bold text-2xl">
                    {item.roomName}
                  </h1>
                  <h3 className="absolute text-xl sm:top-[10%] top-[53%] right-5">
                  {item.price}
                  </h3>
                </span>

                <span className="text-gray-500 font-bold text-md mb-1">
                  {" "}
                  {
                 formatDate(item.dateReservation)
                  } 
                    {/*format(item.dateReservation, "EEEE dd 'de' MMM", { locale: es })*/}
                    
                </span> 
          
                
                <p className="text-gray-500 font-bold text-md mb-1">
                  {item.start}-{item.end}
                </p>

                <p className="text-gray-500 text-md mb-1">
                  Reserva para {item.capacidadMin} personas
                </p>
                
                <p className="text-gray-500 text-md mb-1">
                  Persona adicional {item.pricePerson}€/persona. Si sois más la
                  diferencia se paga en el local
                </p>
                <p className="text-gray-500 text-md">
                  Capacidad Max: {item.capacidad} personas.
                </p>

             
              </div>
              <div
                className="absolute bottom-2 right-2 sm:bottom-5 sm:right-5 cursor-pointer"
                onClick={() => handleRemoveElementCar(item.id)}
              >
                <span className="text-gray-500"> Borrar</span>
                <i className="las la-trash-alt text-gray-500 text-[21px]"></i>
              </div>
            </div>

            {/* <div className="w-full m-5 grid grid-cols-2 gap-4 mb-24">
              <div className="text-gray-900">
                <i className="las la-calendar text-gray-400 text-[21px]"></i>
                <span className="text-gray-400 ">date</span>
                <br />
                <span className="font-bold sm:text-[18px] text-lg">
                  {" "}
                  {selectedDate
                    ? format(selectedDate, "EEEE dd 'de' MMM", { locale: es })
                    : "Fecha no seleccionada"}
                </span>
              </div>

              <div className="text-black">
                <i className="las la-user-friends text-gray-400 text-[21px]"></i>
                <span className="text-gray-400 ">Guest</span>
                <p>{item.capacidad}</p>
              </div>
            </div> */}
          </div>
        ))}
         <div className=" items-center flex justify-left m-5">
         <span className="text-gray-800 mb-1">
                  {" "}
                  <h1 className="text-black font-bold text-2xl">
                    Gastos de Gestión
                  </h1>
                  <h3 className="absolute  text-xl right-20 ">
                  { gestionCost}
                  </h3>
                </span>
          
          </div> 

        <div className=" items-center flex justify-left m-5">
       
                
          <span className=" text-gray-500 text-sm">
            Aclaración: <br />
            No se admiten cambios ni devoluciones <br />
            No se puede traer comida ni bebida de fuera del establecimiento.{" "}
          </span>
        </div>

        <div className="items-center sticky md:flex md:relative bottom-0 ml-2 mr-2 justify-center mb-10 mt-[15%]">
          <button
            className="xl:w-[720px] lg:w-[720px] sm:w-[600px] md:w-[700px] w-full mr-5 h-[50px] text-xl text-white bg-purple-700 rounded-full hover:bg-purple-800 hover:text-white transition-colors duration-300"
            onClick={handleRedirectLogin}
          >
            Ir a pagar | {totalPrice}€
          </button>
        </div>
      </div>
    </>
  );
};

export default InformacionCarrito;
